<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0" rounded="0">
          <v-btn dark icon large @click="reset" class="ml-1">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon v-if="!isMobile" class="mr-3">mdi-account</v-icon>Process a
            user file</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            large
            @click="reset"
            class="ml-1 hidden"
            v-if="userProfile.clientId != 1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-autocomplete
            v-else
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            style="max-width: 250px;"
            v-model="clientId"
            @change="refreshClientId"
            :disabled="
              slideKey > keys.review || slideKey == keys.submittingValidation
            "
          ></v-autocomplete>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 mr-0"
          justify="center"
          align="center"
          height="100%"
        >
          <v-col
            cols="11"
            sm="11"
            :md="slideKey == keys.format ? 11 : 4"
            :lg="slideKey == keys.format ? 11 : 4"
            :xl="slideKey == keys.format ? 11 : 4"
            class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <div>
              <div class="d-flex justify-center align-center mt-10">
                <v-btn
                  v-if="slideKey == keys.format || slideKey == keys.settings"
                  :width="slideKey == keys.settings ? 40 : isMobile ? 90 : 110"
                  rounded
                  depressed
                  :color="'brandCyan'"
                  class="white--text font-weight-bold mr-1"
                  @click="
                    if (slideKey == keys.settings) {
                      goToSlide(keys.format);
                    } else {
                      resetAllData(true);
                    }
                  "
                >
                  <v-icon
                    color="white"
                    :class="{ 'mr-1': slideKey != keys.settings }"
                    v-if="!isMobile || slideKey == keys.settings"
                    >mdi-arrow-left-circle</v-icon
                  >
                  <span
                    v-if="slideKey == keys.format"
                    style="margin-top:2px;"
                    >{{ "Reset" }}</span
                  >
                </v-btn>
                <v-progress-linear
                  :value="
                    slideKey <= keys.review
                      ? ((slideKey % 20) + 1) * 25
                      : progressBarValue
                  "
                  :color="processingFile ? 'brandDarkGreen' : 'brandGreen'"
                  height="40"
                  class="pill-nav"
                  :class="{ 'mx-5': slideKey == keys.format }"
                >
                  <!-- <div style="left: 0; width: 20%; height: 40px; background: red;"></div> -->
                  <div
                    class="d-flex justify-space-between align-center full-width px-2"
                  >
                    <div
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.upload }"
                    >
                      <v-icon
                        color="white"
                        :small="slideKey == keys.settings || isMobile"
                        >mdi-file</v-icon
                      >
                    </div>
                    <div
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.format }"
                    >
                      <v-icon
                        color="white"
                        :small="slideKey == keys.settings || isMobile"
                        >mdi-account-multiple</v-icon
                      >
                    </div>
                    <div
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.settings }"
                    >
                      <v-icon
                        color="white"
                        :small="slideKey == keys.settings || isMobile"
                        >mdi-cog</v-icon
                      >
                    </div>
                    <!-- <div
                  @click="goToSlide(keys.csv)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.csv }"
                >
                  <v-icon color="white">mdi-upload</v-icon>
                </div> -->
                    <div
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{
                        'active-pill':
                          slideKey == keys.review ||
                          slideKey == keys.submittingValidation
                      }"
                    >
                      <v-progress-circular
                        color="white"
                        indeterminate
                        :size="25"
                        :width="4"
                        v-if="slideKey == keys.submittingValidation"
                      ></v-progress-circular>
                      <v-icon
                        color="white"
                        :small="slideKey == keys.settings || isMobile"
                        v-else
                        >mdi-rocket-launch</v-icon
                      >
                    </div>
                  </div>
                </v-progress-linear>
                <v-btn
                  :width="slideKey == keys.settings ? 40 : isMobile ? 90 : 110"
                  rounded
                  depressed
                  :color="keys.review == slideKey ? 'brandGreen' : 'brandCyan'"
                  class="white--text font-weight-bold ml-1"
                  @click="goToNextSlide"
                  :disabled="disableContinueButton"
                >
                  <v-icon
                    color="white"
                    class="mr-1"
                    v-if="slideKey == keys.sent"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                  <v-icon
                    color="white"
                    :class="{ 'mr-1': slideKey != keys.settings }"
                    v-else-if="
                      (!isMobile && slideKey != keys.review) ||
                        slideKey == keys.settings
                    "
                    >mdi-arrow-right-circle</v-icon
                  >
                  <span
                    v-if="slideKey !== keys.sent && slideKey !== keys.settings"
                    style="margin-top:2px;"
                    >{{
                      slideKey == keys.sent
                        ? "Close"
                        : slideKey == keys.review
                        ? "Ready"
                        : "Next"
                    }}</span
                  >
                </v-btn>
              </div>

              <transition :name="slideDirection" mode="out-in">
                <div
                  v-if="slideKey === keys.upload"
                  :key="keys.upload"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Upload your CSV file
                    </p>
                    <div
                      class="d-flex flex-column align-center"
                      @drop.prevent="dragAndDropFileChanged"
                      @dragover.prevent
                    >
                      <v-btn
                        rounded
                        depressed
                        color="brandCyan"
                        width="210"
                        class="white--text font-weight-bold mt-12 mx-auto"
                        :loading="selectingFileFlag"
                        @click="uploadFile"
                        @drop.prevent="dragAndDropFileChanged"
                        @dragover.prevent
                        ><v-icon color="white" class="mr-1" v-if="!isMobile"
                          >mdi-upload</v-icon
                        ><span style="margin-top:2px;"
                          >Upload your file</span
                        ></v-btn
                      >
                      <input
                        id="uploader"
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept=".csv"
                        @change="fileChanged"
                        @blur="fileChanged"
                      />
                      <p class="mt-2 word-break">{{ fileName }}</p>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.settings"
                  :key="keys.settings"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Edit any extra details before you upload
                    </p>
                    <p class="font-weight-bold">Roles:</p>
                    <v-select
                      :items="visibleRoles"
                      item-text="displayName"
                      item-value="id"
                      label="Admin Roles"
                      v-model="selectedRoles"
                      multiple
                      chips
                      attach
                      :loading="loading.roles"
                      menu-props="auto"
                      deletable-chips
                      clear
                      hide-selected
                      hide-details
                      outlined
                      color="brandCyan"
                      no-data-text="No roles available"
                    ></v-select>
                    <v-btn
                      text
                      small
                      class="mt-3"
                      @click="routeToRoles"
                      v-if="permissions.includes('vue:read:accountmanagement')"
                      ><v-icon color="grey">mdi-help-circle</v-icon>What are
                      roles?</v-btn
                    >
                    <v-divider class="mt-3" color="darkGray" />
                    <v-radio-group
                      v-model="userAuthType"
                      @change="toggleOnboardingCheckbox"
                    >
                      <p class="font-weight-bold">Login method:</p>
                      <v-radio
                        label="Email & password"
                        value="PASSWORD"
                        color="brandCyan"
                        class="mb-5"
                      ></v-radio>
                      <v-radio
                        label="One-time password via email"
                        value="PASSWORDLESS_EMAIL"
                        color="brandCyan"
                        class="mb-5"
                      ></v-radio>
                      <v-radio
                        label="One-time password via SMS"
                        value="PASSWORDLESS_SMS"
                        color="brandCyan"
                      ></v-radio>
                    </v-radio-group>
                    <div v-if="userAuthType != 'PASSWORDLESS_EMAIL'">
                      <v-checkbox
                        :label="
                          userAuthType != 'PASSWORDLESS_SMS'
                            ? 'Send invite email'
                            : 'Send invite text'
                        "
                        v-model="sendInviteToggle"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleOnboardingCheckbox"
                        color="brandCyan"
                      />

                      <input
                        v-model="scheduledOnboardingDate"
                        class="timestamp-input mb-2"
                        type="datetime-local"
                        v-if="sendInviteToggle === true"
                      />
                      <p v-else-if="sendInviteToggle === false" class="mb-1">
                        The invite
                        {{ userAuthType == "PASSWORD" ? "email" : "text" }} can
                        be sent anytime from the People page
                      </p>
                    </div>
                    <p v-else-if="userAuthType == 'PASSWORDLESS_EMAIL'">
                      Note: No invite notificiation will get sent for email
                      one-time password users. We recommend messaging them from
                      the Communications page if you wish to invite them.
                    </p>
                    <v-divider class="mb-5 mt-4" color="darkGray" />
                    <p class="font-weight-bold">Optionally create a group:</p>
                    <!-- <p class="word-break mt-4 mb-1">
                      If you'd like to create a group from the people created in
                      this upload, enter a group name for them.
                    </p> -->
                    <v-form v-model="form.groupName">
                      <v-text-field
                        v-model="groupName"
                        label="Group name"
                        outlined
                        dense
                        color="brandCyan"
                        :rules="formRules.string255Optional"
                      ></v-text-field>
                    </v-form>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.submittingValidation"
                  :key="keys.submittingValidation"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Checking your CSV for errors...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.format"
                  :key="keys.format"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-2 mt-4">
                      Now Label CSV Columns With User Attributes
                    </p>
                    <p class="darkGrey--text mb-4">
                      Any columns not selected will not be updated.
                    </p>
                    <h3>Total Rows: {{ nonEmptyRows.length }}</h3>
                    <div class="d-flex justify-space-between align-center">
                      <v-switch
                        color="brandCyan"
                        label="File includes a header row"
                        v-model="headerRow"
                      />
                    </div>
                    <p v-if="!nonEmptyRows.length" class="error--text">
                      Empty file
                    </p>
                    <p
                      v-else-if="missingRequiredHeaders.length"
                      class="error--text"
                    >
                      Required headers missing:
                      {{ missingRequiredHeaders.join(", ") }}
                    </p>
                    <p
                      v-else-if="missingRequiredValues.length"
                      class="error--text"
                    >
                      Required fields missing. Check for any rows missing:
                      {{ missingRequiredValues.join(", ") }}
                    </p>
                    <div class="csv-container">
                      <div
                        v-for="(row, rowIndex) in nonEmptyRows"
                        :key="rowIndex"
                        class="d-flex flex-column"
                      >
                        <!-- {{ row }} -->
                        <div class="headers d-flex" v-if="rowIndex == 0">
                          <v-btn icon small color="red" class="hidden"
                            ><v-icon>mdi-trash-can</v-icon></v-btn
                          >

                          <div
                            v-for="(col, colIndex) in row.data"
                            :key="colIndex"
                            class="csv-col"
                            :class="{
                              'light-grey-background': !!selectedHeaders[
                                colIndex
                              ]
                            }"
                          >
                            <v-autocomplete
                              label="Column label"
                              :key="colIndex"
                              :items="columnOptions"
                              color="brandCyan"
                              @input="updateColumnLabel(colIndex, $event)"
                              :value="selectedHeaders[colIndex]"
                              item-text="name"
                              item-value="value"
                              class="column-selector"
                            >
                              <template v-slot:item="{ item }">
                                <span
                                  :class="{
                                    'v-list-item--disabled theme--light': !!computedSelectedHeaders.find(
                                      x => x == item.value
                                    )
                                  }"
                                >
                                  {{ item.name }}
                                </span>
                              </template>
                            </v-autocomplete>
                          </div>
                        </div>
                        <!-- [-1, colIndex].includes(
                                    computedSelectedHeaders.findIndex(
                                      y => y == x
                                    )
                                  ) -->
                        <div class="d-flex align-center">
                          <v-btn
                            icon
                            small
                            color="red"
                            title="Delete row"
                            @click="deleteRow(row.id)"
                            ><v-icon>mdi-trash-can</v-icon></v-btn
                          >
                          <div
                            v-for="(col, colIndex) in row.data"
                            :key="colIndex"
                            class="csv-col"
                          >
                            {{ col }}
                          </div>
                        </div>
                        <!-- <v-select
                          label="Column label"
                          :items="columnOptions"
                          item-text="name"
                          item-value="value"
                        />
                        <v-col v-for="(col, colIndex) in row" :key="colIndex">{{
                          col
                        }}</v-col> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.submitting"
                  :key="keys.submitting"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Processing users...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.sent"
                  :key="keys.sent"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    All done!
                  </p>
                  <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
                  <div class="d-flex justify-center mt-6">
                    <Robin
                      :height="120"
                      :width="140"
                      class=""
                      :loop="false"
                      animation="backflip"
                    />
                  </div>
                  <div class="d-flex justify-center mt-12 mb-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="reset"
                      width="95"
                      ><span>Close</span></v-btn
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.error"
                  :key="keys.error"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    We had some trouble creating your users. <br />Please check
                    your CSV and try again.
                  </p>
                  <p class="full-width">
                    If this problem continues, feel free to reach out to
                    customer service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <div
                    v-for="(error, i) in fileErrors"
                    :key="i"
                    class="text-left"
                  >
                    <span>Row {{ error.index }} - {{ error.error }}</span>
                  </div>

                  <div class="d-flex justify-center my-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="slideKey = keys.format"
                      ><v-icon class="mr-1">mdi-chevron-left</v-icon
                      ><span style="margin-top:1px;">Go back</span></v-btn
                    >
                  </div>
                </div>
              </transition>
            </div>
            <div
              v-if="slideKey < keys.format"
              class="mt-2 d-flex flex-column align-center"
            >
              <p
                v-if="slideKey == keys.upload"
                class="font-weight-bold text-h6"
              >
                Do you need a template?
              </p>
              <v-btn
                text
                color="brandCyan"
                class="text-none"
                @click="downloadTemplate"
                :loading="loadingTemplate"
                ><v-icon class="mr-2">mdi-download</v-icon>Download user
                template</v-btn
              >
            </div>
            <div
              v-else-if="slideKey == keys.review"
              class="mt-2 d-flex flex-column align-center"
            >
              <v-btn
                rounded
                depressed
                color="brandCyan"
                class="white--text"
                @click="createUsers"
                :disabled="disableContinueButton"
              >
                Process file</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break text-left exit-warning-text">
            There was an error parsing your file
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="(slideKey = keys.upload), (dialogError = false)"
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OnboardingService from "@/services/OnboardingService";
import TriggerService from "@/services/TriggerService";
import UserService from "@/services/UserService";

import { truncateNumber } from "@/shared_data/functions";
import { formRules } from "@/shared_data/data";

import Robin from "@/components/Robin";

import currency from "currency.js";
import moment from "moment";
import { mapState } from "vuex";

function initialState() {
  return {
    slideKey: 20,
    dialogConfirmation: false,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    source: "USER_CSV_WIZARD",
    keys: {
      upload: 20,
      format: 21,
      settings: 22,
      submittingValidation: 23,
      review: 24,
      submitting: 25,
      // send: 101,
      sent: 102,
      error: 103
    },
    progressBarValue: 0,
    navigationItemWidth: 200,
    loadingTemplate: false,
    selectingFileFlag: false,
    fileData: null,
    fileName: null,
    file: null,
    validatingFile: false,
    processingFile: false,
    fileRows: [],
    fileErrors: [],
    // fileCols: [],
    displayFileResultDrawer: false,
    headerRow: false,
    columnOptions: [
      { name: "First name", value: "firstName", alternate: "legalFirstName" },
      { name: "Last name", value: "lastName", alternate: "legalLastName" },
      { name: "Business country", value: "businessCountry" },
      { name: "Email for login", value: "businessEmail" },
      { name: "Phone number for login", value: "businessPhone" },
      // Alphabetical after this
      { name: "Birthday", value: "birthday" },
      { name: "Business address", value: "businessAddress" },
      { name: "Business address 2", value: "businessAddress2" },
      { name: "Business city", value: "businessCity" },
      { name: "Business state", value: "businessState" },
      { name: "Business postal code", value: "businessPostalCode" },
      { name: "Custom field 1", value: "token1", alternate: "custom1" },
      { name: "Custom field 2", value: "token2", alternate: "custom2" },
      { name: "Custom field 3", value: "token3", alternate: "custom3" },
      { name: "Display name", value: "displayName" },
      {
        name: "Home address",
        value: "personalAddress",
        alternate: "homeAddress"
      },
      {
        name: "Home address 2",
        value: "personalAddress2",
        alternate: "homeAddress2"
      },
      { name: "Home city", value: "personalCity", alternate: "homeCity" },
      { name: "Home state", value: "personalState", alternate: "homeState" },
      {
        name: "Home postal code",
        value: "personalPostalCode",
        alternate: "homePostalCode"
      },
      {
        name: "Home country",
        value: "personalCountry",
        alternate: "homeCountry"
      },
      { name: "Job start date", value: "jobStartDate" },
      { name: "Job title", value: "jobTitle" },
      { name: "Language", value: "language" },
      { name: "Manager email", value: "managerEmail" },
      { name: "Organization", value: "organization" },
      { name: "Personal email", value: "personalEmail" },
      { name: "Personal phone", value: "personalPhone" },
      { name: "Role start date", value: "roleStartDate" },
      { name: "User ID at Client", value: "userIdAtClient" }
    ],
    selectedHeaders: {},
    headers: [],
    localRoles: [],
    selectedRoles: [],
    loading: {
      roles: false
    },
    form: {
      groupName: true
    },
    groupName: null,
    formRules,
    userAuthType: "PASSWORD",
    sendInviteToggle: false,
    scheduledOnboardingDate: null
  };
}

export default {
  name: "UserCreatorFileWizard",
  title: "File Wizard | Whistle",
  components: {
    Robin
  },
  props: {
    roles: Array
  },
  data() {
    return initialState();
  },
  created() {
    if (!this.roles || !Array.isArray(this.roles) || !this.roles.length)
      this.getRoles();
    this.createPlatformActivity(`OPENED_${this.source}`);
    window.addEventListener("resize", this.refreshScreenSize);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    this.refreshScreenSize();
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }

    window.removeEventListener("resize", this.refreshScreenSize);
  },
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return currency(amount, { separator: ",", symbol: "" }).format();
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      const cols = this.selectedHeaders;
      Object.assign(this.$data, initialState());
      console.log({ cols });
      this.selectedHeaders = cols;
    },
    reset() {
      this.resetAllData();
      this.$emit("reset");
      this.$router.push("/people");
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    refreshScreenSize() {
      setTimeout(() => {
        if (document.getElementsByClassName("pill-nav").length > 0) {
          this.navigationItemWidth = document.getElementsByClassName(
            "pill-nav"
          )[0].offsetWidth;
        } else {
          this.navigationItemWidth = 200;
        }
        console.log("Nav width ", this.navigationItemWidth);
      }, 30);
    },
    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      var platformActivityEvent = null;
      // We have to block if the user has already submitted
      if (
        this.slideKey >= 1 &&
        this.slideKey < this.keys.submitting &&
        !this.validatingFile
      ) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();

      if (this.slideKey == this.keys.upload) {
        this.validateCSV();
      } else if (this.slideKey === this.keys.settings) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.createUsers();
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.slideKey = this.route[routeIndex + 1];

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },
    uploadFile() {
      this.selectingFileFlag = true;
      window.addEventListener(
        "focus",
        () => {
          this.selectingFileFlag = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    fileChanged(e) {
      if (e.target.files.length > 0) {
        console.log(e.target.files[0]);
        this.fileData = e.target.files[0];
        this.fileName = e.target.files[0].name;
        this.file = URL.createObjectURL(e.target.files[0]);
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.selectingFileFlag = false;
      }
    },
    dragAndDropFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        console.log(e.dataTransfer.files[0]);
        // this.$set(
        //   this.content,
        //   "fileData",
        //   URL.createObjectURL(e.dataTransfer.files[0])
        // );
        this.fileData = e.dataTransfer.files[0];
        this.fileName = e.dataTransfer.files[0].name;
        this.file = URL.createObjectURL(e.dataTransfer.files[0]);
        this.selectingFileFlag = false;
        // this.$set(this.newBadge, "imageFile", e.dataTransfer.files[0]);
      }
    },
    validateCSV() {
      this.validatingFile = true;
      this.slideKey = this.keys.submittingValidation;
      var object = {
        file: this.fileData,
        source: this.source
      };
      console.log({ object });
      // Display loading screen
      // this.dialogConfirmation = false;
      // this.slideKey = this.keys.send;
      OnboardingService.validateUserFileV2(object)
        .then(x => {
          console.log(x);
          // this.fileCols = x.cols;
          let arr = [];
          let header_arr = new Array(x.col_count);
          x.rows.forEach((y, i) => {
            arr.push({
              id: i,
              header: null,
              data: y
            });
          });
          this.fileRows = arr;
          this.headers = header_arr;

          const firstValInFile = this.fileRows?.[0]?.data?.[0];
          // if (
          //   firstValInFile &&
          //   (firstValInFile == "legalFirstName" ||
          //     firstValInFile == "firstName")
          // )
          //   this.headerRow = true;

          // Here we try to auto assign column headers to values
          const matchedCol = this.columnOptions.find(
            x =>
              x.name?.toLowerCase() == firstValInFile?.toLowerCase() ||
              x.value?.toLowerCase() == firstValInFile?.toLowerCase() ||
              (x?.alternate &&
                x?.alternate?.toLowerCase() == firstValInFile?.toLowerCase())
          );
          if (matchedCol) {
            console.log("Parsing all columns to match");
            this.fileRows?.[0]?.data?.forEach((header, index) => {
              const matchedHeader = this.columnOptions.find(
                x =>
                  x.name?.toLowerCase() == header?.toLowerCase() ||
                  x.value?.toLowerCase() == header?.toLowerCase() ||
                  (x?.alternate &&
                    x?.alternate?.toLowerCase() == header?.toLowerCase())
              );
              if (header == "legalLastName")
                console.log("PARSING LAST NAME", matchedHeader);
              if (matchedHeader)
                this.updateColumnLabel(index, matchedHeader.value);
            });
          }
        })
        .catch(error => {
          console.log("Error validating file! ", error);
          this.dialogError = true;
          this.errorText =
            "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
          if (error.error_code && error.error) {
            if (error.error.includes("Empty file"))
              this.errorText = "The attached file was empty.";
            else if (error.error_code == "1070009")
              this.errorText = "No file was attached.";
            else if (error.error_code == "1070100")
              this.errorText = "The selected budget has insufficient funds.";
          }
        })
        .finally(() => {
          console.log("Routing to review");
          this.validatingFile = false;
          this.slideKey = this.keys.format;
        });
    },
    async createUsers() {
      // Show loading bar
      console.log("Creating users! ", this.keys.submitting);
      this.slideKey = this.keys.submitting;
      this.processingFile = true;
      const progressIncrement = 2;
      const sleepDur = 100;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      // setTimeout(() => {
      //   this.processingFile = false;
      //   this.loadBarSleeper(progressIncrement, sleepDur, true);
      // }, 12000);

      try {
        // Let's prep the array
        // var userArray = this.fileRows;

        var object = this.formattedObject;
        // var object = {
        //   users: paymentsArray,
        //   description: this.memo,
        //   sourceUserId: this.userProfile.userId,
        //   source: "PAYMENTS_CSV"
        // };

        console.log(object);
        // Display loading screen
        this.dialogConfirmation = false;
        var response = await OnboardingService.createUserV2(object, {
          wait: "true",
          notify: "true",
          screen: "file_wizard",
          file: this.fileName
        });
        this.processingFile = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);
        // if (typeof response == "string") {
        // if (response.startsWith("The sum of all payouts")) {
        //   this.dialogError = true;
        //   this.errorText =
        //     "The total payment sum is more than the selected budget's balance.";
        // }
        // } else
        this.fileErrors = [];
        if (
          response.error ||
          response.result.find(x => x.invalid || x.failed)
        ) {
          this.slideKey = this.keys.error;

          response.result.forEach((row, index) => {
            if (row.invalid || row.failed)
              this.fileErrors.push({
                index: index + 1,
                error: row.invalidReason || row.failedReason
              });
          });
          // this.fileErrors = response.result.filter(x => x.invalid || x.failed);
        } else {
          // Display success screen
          this.slideKey = this.keys.sent;
        }
        // })
      } catch (error) {
        console.log("Error creating users! ", error);
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.processingFile || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          if (this.progressBarValue < 90) this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      // this.slideKey = this.keys.upload;
      // this.fileRows = [];
      // // this.fileCols = [];
      // this.fileData = null;
      // this.file = null;
      // this.fileName = null;
      // this.selectingFileFlag = false;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: this.source,
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    async downloadTemplate() {
      try {
        this.loadingTemplate = true;
        await OnboardingService.downloadTemplate();
      } catch (e) {
        console.log("Error downloading file ", e);
      } finally {
        this.loadingTemplate = false;
      }
    },
    updateColumnLabel(index, val) {
      // console.log({ index, val });
      this.$set(this.selectedHeaders, index, val);
      // console.log(Object.values(this.selectedHeaders));
      // If any other indexes have this value, then we clear those
      Object.keys(this.selectedHeaders).forEach(key => {
        if (key != index && this.selectedHeaders[key] == val)
          this.selectedHeaders[key] = undefined;
      });
    },
    filterColumnLabelItems(colIndex) {
      // Filters for any unselected column headers unless it's the column that has already selected it - in which case we show it there

      let arr = this.columnOptions.filter(orig => {
        const index = this.computedSelectedHeaders.findIndex(
          selected => selected == orig.value
        );
        return [-1, colIndex].includes(index);
      });
      console.log("Refreshing column label", arr.length);
      // console.log(arr.length);
      return arr;
    },
    deleteRow(id) {
      const index = this.fileRows.findIndex(x => x.id == id);
      console.log({ id, index });
      if (index != -1) this.fileRows.splice(index, 1);
    },
    routeToRoles() {
      let routeData = this.$router.resolve({
        name: "accountManagement",
        query: { tab: "roles" }
      });
      window.open(routeData.href, "_blank");
    },
    async getRoles() {
      try {
        this.loading.roles = true;
        const response = await UserService.getRoles();

        console.log("Roles Response: ", response);
        this.localRoles = response;
      } catch (e) {
        console.log("Error getting roles", e);
      } finally {
        this.loading.roles = false;
      }
    },
    toggleOnboardingCheckbox() {
      if (this.sendInviteToggle && !this.scheduledOnboardingDate) {
        this.scheduledOnboardingDate = moment().format("YYYY-MM-DDTHH:mm");
      }

      // refresh validation so when they change, it removes any old validation from phone or email
      if (this.$refs.userForm) this.$refs.userForm.validate();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.keys.upload,
          this.keys.format,
          this.keys.settings,
          this.keys.submittingValidation,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.review) {
            array.push(true);
          } else if (page == this.keys.upload && this.file) {
            array.push(true);
          } else if (
            page == this.keys.settings &&
            this.form.groupName &&
            this.userAuthType
          ) {
            array.push(true);
          } else if (
            page == this.keys.format &&
            // !this.fileErrors.length &&
            !this.missingRequiredHeaders.length &&
            !this.missingRequiredValues.length &&
            this.formattedObject.length
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    // fileErrors() {
    //   return this.fileRows.filter(x => x.amountError || x.emailError);
    // },
    computedSelectedHeaders() {
      // Array
      // return this.headers.filter(x => !!x);
      // Object
      return Object.values(this.selectedHeaders);
    },
    computedUnselectedHeaders() {
      return this.columnOptions.filter(
        x => !this.computedSelectedHeaders.some(y => x.value == y)
      );
    },
    missingRequiredHeaders() {
      const required = [
        { value: "firstName", name: "First name" },
        { value: "lastName", name: "Last name" },
        { value: "businessCountry", name: "Business country" }
      ];
      let unmet = required.filter(
        required =>
          !this.computedSelectedHeaders.some(
            selected => selected == required.value
          )
      );

      if (
        !this.computedSelectedHeaders.find(
          x => x == "businessPhone" || x == "businessEmail"
        )
      )
        unmet.push({ value: "businessEmail", name: "Business email" });
      return unmet.map(x => x.name);
    },
    missingRequiredValues() {
      // if (this.missingRequiredHeaders.length) return [];
      let missing = [];
      this.formattedObject.forEach(row => {
        console.log(row);
        if (!row.firstName) missing.push("First name");
        if (!row.lastName) missing.push("Last name");
        if (!row.businessCountry) missing.push("Business country");
        if (!row.businessEmail && !row.businessPhone)
          missing.push("Business email");
      });
      return [...new Set(missing)];
    },
    nonEmptyRows() {
      let arr = this.headerRow ? this.fileRows.slice(1) : this.fileRows;
      return arr.filter(x => !!x.data.find(y => !!y));
    },
    formattedObject() {
      let users = [];
      this.nonEmptyRows.map(row => {
        let obj = {};

        Object.keys(this.selectedHeaders).forEach(keyIndex => {
          if (this.selectedHeaders[keyIndex]) {
            obj[this.selectedHeaders[keyIndex]] = row.data[Number(keyIndex)];
          }
        });

        if (Object.keys(obj).length) {
          obj.clientId = this.clientId;

          // Now add user auth
          if (this.userAuthType == "PASSWORDLESS_EMAIL")
            obj.emailPasswordlessEnabled = true;
          else if (this.userAuthType == "PASSWORDLESS_SMS")
            obj.smsPasswordlessEnabled = true;
          else obj.passwordEnabled = true;

          if (
            this.sendInviteToggle &&
            (obj.passwordEnabled || obj.smsPasswordlessEnabled) &&
            this.scheduledOnboardingDate
          )
            obj.scheduledOnboardingDate = moment(this.scheduledOnboardingDate)
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ");
          else if (!this.sendInviteToggle) obj.scheduledOnboardingDate = null;

          obj.roles = this.selectedRoles;
          obj.groupName = this.groupName;
          users.push(obj);
        }
      });
      return users;
    },
    visibleRoles() {
      // We grab the props directly in the scenario that the parent component is still fetching the roles.
      // Then once they're returned, we fill them into the array (so we don't have to watch for the response)
      var tempRoles = [];
      if (this.roles) tempRoles = this.roles;
      else tempRoles = this.localRoles;
      console.log("ROLES: ", tempRoles);
      var roles = JSON.parse(JSON.stringify(tempRoles)).filter(
        x => x.name != "Participant"
      );
      if (this.clientId != 1) {
        return roles.filter(x => !x.name.includes("Whistle"));
      }

      return roles;
    }
    // defaultHeaders() {
    //   if (this.headerRow) return this.fileRows[0]

    //   return [
    //     "firstName",
    //     "lastName",
    //     "businessCountry",
    //     "businessEmail",
    //     "businessPhone",
    //     "organization",
    //     ""
    //   ]
    // }
  },
  watch: {
    // budgets: function(newVal, oldVal) {
    //   if (oldVal.length == 0) {
    //     console.log("Got budget watcher", oldVal);
    //     // this.preloadBudget();
    //   }
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.people-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 200px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}

/* Adds grey background to search box */
.search-field >>> .v-input__slot {
  /* border-style: none !important;
  border-radius: 2px !important; */
  background: var(--v-lightGrey-base) !important;
}

.amount-text-field >>> .v-input__slot {
  max-width: 125px;
  width: 125px;
}

.amount-text-field >>> .v-text-field__details {
  padding-left: 4px;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}
.side-nav-text {
  color: var(--v-brand-base);
}
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-panel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 7px;
}
.budget-select >>> .v-list-item {
  font-size: 0.8125rem;
}
.category-div {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}
.selected-category {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
}
.category-container {
  cursor: pointer;
}
.category-container:hover .category-div {
  background-color: #e8e8ec;
}
.selected-category-background {
  background-color: #e8e8ec;
}
.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /* color: rgba(0, 0, 0, 0.38); */
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

/* Used for the pill nav at the top of the main card */
.pill-nav {
  /* background: var(--v-grey-base); */
  /* background: var(--v-brandDarkGreen-base); */
  /* opacity: 0.3; */
  width: calc(100% - 20px);
  max-width: 400px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 999px;
  /* padding: 0 20px 0 20px; */
}
.pill-nav >>> .pill-item {
  border-radius: 999px;
  /* min-width: 60px; */
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
}
.pill-nav >>> .active-pill {
  /* border-radius: 999px; */
  /* background: var(--v-brandGreen-base); */
}

.budget-name-container {
  width: calc(100% - 120px);
}

.name-field {
  width: calc(((100% - 28px) / 12) * 4);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.email-field {
  width: calc(((100% - 28px) / 12) * 6);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.amount-field {
  width: calc(((100% - 28px) / 12) * 2);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.csv-container {
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: calc(100vw - 100px);
  min-height: 500px !important;
  max-height: calc(100vh - 300px);
}
.csv-col {
  /* width: 100px; */
  /* word-break: wrap; */
  border: 0.5px solid black;
  min-width: 150px;
  max-width: 150px;
  min-height: 80px;
  max-height: 80px;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 4px;
}

.column-selector >>> .v-select__selection {
  font-size: 12px !important;
  /* background: var(--v-lightGrey-base) !important; */
}
</style>
